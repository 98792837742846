import { createSSRApp } from 'vue';
//import { createWebHistory } from 'vue-router'
//import createRouter from './router'
import App from './App.vue'
import Unicon from 'vue-unicons'
import MetaInfo from 'vue-meta-info'
import router from './router'
import axios from "axios";

//import VueAxios from 'vue-axios'

import {uniMobileVibrate,uniEnvelopeShare,uniAnalysis, uniBookReader, uniBracketsCurly, uniAppsMonochrome, uniCog, uniPresentationLinesAlt, uniLayerGroupMonochrome, uniCarWash, uniAnalytics, uniPanelAdd, uniArrowUp, uniDatabase, uniArrowUpLeft, uniHouseUser, uniCodeBranch } from 'vue-unicons/dist/icons'


Unicon.add([uniMobileVibrate,uniEnvelopeShare,uniAnalysis, uniBookReader, uniBracketsCurly, uniAppsMonochrome, uniCog, uniPresentationLinesAlt, uniLayerGroupMonochrome, uniCarWash, uniAnalytics, uniPanelAdd, uniArrowUp, uniDatabase, uniArrowUpLeft, uniHouseUser, uniCodeBranch])
//const router = createRouter(createWebHistory())
const app = createSSRApp(App)
//createApp(App).use(Unicon).use(MetaInfo).use(router).mount('#app')
app.use(Unicon)
app.use(MetaInfo)
app.use(router)
//app.use(VueAxios, axios);
app.config.globalProperties.$http = axios

router.isReady().then(() => {
  app.mount('#app')
})