<template>
  <router-view />
  <div id="nav">
    <div class="bottom">
      <div class="bottomline">
        ADDRESS: Chengdu , China Gaoxin Dist, Tianfu Five Road Jingrong
        International Piazza Building 4A, 12F Mail: sales@sccrux.com Tel:
        86-28-61869087
      </div>
      <div class="bottomline">
        地址: 四川省成都市 高新区天府五街200号 菁蓉国际广场4号楼A座12楼
        邮编：610041 Mail：sales@sccrux.com 电话：028-61869087
      </div>
      <div class="bottomline">
        Copyright © 2019 四川南十字科技有限公司
        <a href="http://beian.miit.gov.cn/" class="urlclass"
          >蜀ICP备19004893号-5</a
        >
        Designed by SCCRUX. All Rights Reserved.
      </div>
      <div style="display: none">
        南十字数据,SQL Server规划,SQL Server调优,SQL Server运维,SQL
        Server调优专家,Power BI 服务器定义身份验证开发,自定义门户,Power
        BI报表服务器SSO,Power BI嵌入开发,PowerBI服务器多身份验证,Power
        Query开发,四川SQL Server 调优，成都SQL Server 调优
      </div>

      <router-link to="/">Home</router-link> |
      <a href="http://www.sccrux.com" target="_blank">About</a>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "南十字数据——我们很强！",
    meta: [
      {
        name: "description",
        content:
          "南十字数据，SQL Server规划、部署、运维、调优专家。Power BI 服务器定义身份验证开发，自定义门户，Power BI报表服务器SSO，Power BI嵌入开发,PowerBI服务器多身份验证,Power Query开发",
      },
      {
        name: "keywords",
        content:
          "南十字数据,SQL Server规划,SQL Server调优,SQL Server运维,SQL Server调优专家,Power BI 服务器定义身份验证开发,自定义门户,Power BI报表服务器SSO,Power BI嵌入开发,PowerBI服务器多身份验证,Power Query开发",
      },
      {
        name: "anthor",
        content:
          "ADDRESS: Chengdu , China Gaoxin Dist, Tianfu Five Road Jingrong International Piazza Building 4A, 12F Mail: sales@sccrux.com Tel:86-28-61869087",
      },
      {
        name: "robots",
        content:
          "南十字数据,SQL Server规划,SQL Server调优,SQL Server运维,SQL Server调优专家,Power BI 服务器定义身份验证开发,自定义门户,Power BI报表服务器SSO,Power BI嵌入开发,PowerBI服务器多身份验证,Power Query开发",
      },
    ],
  },
};
</script>
 <style>
body {
  margin: 0;
  padding: 0;
}
</style>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.bottom {
  width: 100%;
  background-color: rgb(236, 233, 233);
  text-align: center;

  //height: 100px;

  // position: absolute;
}
.bottomline {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  color: rgb(121, 117, 117);
}
#nav {
  padding: 0px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
